import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import * as OrganizationQuery from 'graphql/organization.graphql';
import useCurrentOrganization from '~/hooks/useCurrentOrganization';
import useSpacesFromCurrentOrg from '~/hooks/useSpacesFromCurrentOrg';
import { sortSpaceByName } from 'utils';
import DropDown from '~/components/Menu/DropDown';
import DropDownItem from '~/components/Menu/DropDownItem';
import Modal from '~/components/Modal';
import { ManagedOrganizations, Organization, Space } from '~/api/data/user/types';
import './style.scss';

export interface ChooseSpaceModalData {
  onSpaceChosen: (spaceId: string) => void;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  header?: string;
  confirmButtonLabel?: string;
  denyLabel?: string;
}

const ChooseSpaceModal: FC<ChooseSpaceModalData> = ({
  onSpaceChosen,
  showModal,
  setShowModal,
  header = 'Choose a space...',
  confirmButtonLabel = 'Ok',
  denyLabel = 'Cancel',
}) => {
  const { data: orgsData } = useQuery<ManagedOrganizations>(OrganizationQuery.ManagedOrganizations);
  const { currentOrg } = useCurrentOrganization();
  const { currentSpace } = useSpacesFromCurrentOrg();
  const modalRef = useRef<HTMLDivElement>(null);

  const orgs = orgsData?.managedOrganizations;
  const [selectedOrg, setSelectedOrg] = useState<Organization>();
  const [selectedSpace, setSelectedSpace] = useState<Space>();

  useEffect(() => {
    if (currentOrg) setSelectedOrg(currentOrg);
    else setSelectedOrg((orgs || [])[0]);
  }, [currentOrg, showModal, orgs]);

  useEffect(() => {
    const currentOrgSelected = selectedOrg && selectedOrg?.id === currentOrg?.id;
    setSelectedSpace(currentOrgSelected ? currentSpace : selectedOrg?.spaces[0]);
  }, [currentOrg, currentSpace, selectedOrg]);

  const selectedOrgSpaces = useMemo(() => {
    return sortSpaceByName(selectedOrg?.spaces || []);
  }, [selectedOrg?.spaces]);

  return (
    <Modal
      modalRef={modalRef}
      header={header}
      headerIcon="document"
      visible={showModal}
      denyLabel={denyLabel}
      handleOnCancel={() => setShowModal(false)}
      confirmlabel={confirmButtonLabel}
      handleOnConfirm={() => {
        onSpaceChosen(selectedSpace?.slug || 'missing');
        setShowModal(false);
      }}
      className="choose-space-modal"
      fullScreenMobile>
      <label htmlFor="org-dropdown" className="label">
        Select an organization:
      </label>
      <DropDown
        htmlId="org-dropdown"
        outsideRef={modalRef}
        openedIcon="arrow_up"
        closedIcon="arrow_down"
        iconSide="RIGHT"
        buttonChildren={<span>{selectedOrg?.name}</span>}>
        {orgs?.map(organization => (
          <DropDownItem key={organization.id} onClick={() => void setSelectedOrg(organization)}>
            {organization.name}
          </DropDownItem>
        ))}
      </DropDown>
      <label htmlFor="spaces-dropdown" className="label">
        Select a Space:
      </label>
      <DropDown
        htmlId="spaces-dropdown"
        outsideRef={modalRef}
        openedIcon="arrow_up"
        closedIcon="arrow_down"
        iconSide="RIGHT"
        buttonChildren={<span>{selectedSpace?.name}</span>}>
        {selectedOrgSpaces.map(space => (
          <DropDownItem
            key={space.id}
            onClick={() => {
              void setSelectedSpace(space);
            }}>
            {space.name}
          </DropDownItem>
        ))}
      </DropDown>
    </Modal>
  );
};

export default ChooseSpaceModal;
