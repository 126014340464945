import React, { FC, ReactNode, useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { APP_CONFIG } from 'api/config';
import { updateTip } from 'api/data/response';
import { isProduction } from 'utils';
import useSessionPreferences from 'hooks/useSessionPreferences';
import ACH from 'components/ACH';
import CreditCard from 'components/CreditCard';
import OfflinePayment from '../OfflinePayment';
import { OnSubmitFunction, PaymentErrorProps } from 'api/data/payment/types';
import { Response } from 'api/data/response/types';
import bankIcon from 'assets/bank.svg';
import cardIcon from 'assets/card.svg';
import cashIcon from 'assets/cash.svg';
import './style.scss';

interface PaymentMethodOptionsProps {
  onSubmit: OnSubmitFunction;
  buttonText: string;
  setPaymentMethod: (paymentMethod: PaymentMethod) => void;
  onResponseChanged?: (response: Response) => void;
  pageId?: string;
  response: Response;
  paymentMethod?: PaymentMethod;
  paymentError?: PaymentErrorProps;
  setLoading: (loading: boolean) => void;
  loading: boolean;
  provider?: string;
  providerId?: string;
  disableInputEmail?: boolean;
  allowOfflinePayments?: boolean;
  handleOnSaveResponse?: () => Promise<void>;
}

export type PaymentMethod = 'card' | 'ach' | 'wallet' | 'offline';

type PaymentComponentsType = {
  name: PaymentMethod;
  component: (
    onSubmit: OnSubmitFunction,
    buttonText: string,
    setLoading: (loading: boolean) => void,
    loading: boolean,
    response: Response,
    onResponseChanged?: (response: Response) => void,
    pageId?: string,
    paymentError?: PaymentErrorProps,
    provider?: string,
    disableInputEmail?: boolean,
    providerId?: string,
  ) => ReactNode;
  label: string;
  secondaryLabel?: string;
  icon: string;
};

const stripePromise = loadStripe(APP_CONFIG.STRIPE);

const paymentComponents: PaymentComponentsType[] = [
  {
    name: 'card',
    component: (
      onSubmit,
      buttonText,
      setLoading,
      loading,
      response,
      onResponseChanged,
      pageId,
      paymentError,
      provider,
      disableInputEmail,
      providerId,
    ) => (
      <CreditCard
        onSubmit={onSubmit}
        onResponseChanged={onResponseChanged}
        buttonText={buttonText}
        pageId={pageId}
        paymentError={paymentError}
        setLoading={setLoading}
        loading={loading}
        provider={provider}
        disableInputEmail={disableInputEmail}
        response={response}
        providerId={providerId}
      />
    ),
    label: 'Card',
    icon: cardIcon,
  },
  {
    name: 'ach',
    component: (
      onSubmit,
      buttonText,
      setLoading,
      loading,
      response,
      onResponseChanged,
      pageId,
      paymentError,
      provider,
      disableInputEmail,
    ) => (
      <ACH
        onSubmit={onSubmit}
        onResponseChanged={onResponseChanged}
        buttonText={buttonText}
        pageId={pageId}
        paymentError={paymentError}
        setLoading={setLoading}
        loading={loading}
        provider={provider}
        disableInputEmail={disableInputEmail}
        response={response}
      />
    ),
    label: 'Bank transfer',
    secondaryLabel: 'Free',
    icon: bankIcon,
  },
  {
    name: 'offline',
    component: (
      onSubmit,
      buttonText,
      setLoading,
      loading,
      response,
      onResponseChanged,
      pageId,
      paymentError,
      provider,
      disableInputEmail,
    ) => (
      <OfflinePayment
        onSubmit={onSubmit}
        onResponseChanged={onResponseChanged}
        buttonText={buttonText}
        pageId={pageId}
        paymentError={paymentError}
        setLoading={setLoading}
        loading={loading}
        provider={provider}
        disableInputEmail={disableInputEmail}
        response={response}
      />
    ),
    label: 'Cash or Check',
    secondaryLabel: 'Free',
    icon: cashIcon,
  },
];

const PaymentMethodOptions: FC<PaymentMethodOptionsProps> = ({
  onSubmit,
  onResponseChanged,
  buttonText,
  pageId,
  response,
  paymentMethod,
  setPaymentMethod,
  paymentError,
  setLoading,
  loading,
  provider,
  disableInputEmail,
  allowOfflinePayments,
  handleOnSaveResponse,
  providerId,
}) => {
  const [prevTip, setPrevTip] = useState(0);
  const { acceptCashAndCheckPayments, acceptInPersonPayments } = useSessionPreferences();

  useEffect(() => {
    if (!handleOnSaveResponse) return;

    const changeTip = (prev: number, newTip: number) => {
      setPrevTip(prev);
      updateTip(newTip);
      void handleOnSaveResponse();
    };

    if (paymentMethod === 'offline') {
      changeTip(response.order.tip.percentage || prevTip, 0);
    } else if (prevTip) {
      changeTip(0, prevTip);
    }
  }, [paymentMethod, handleOnSaveResponse, prevTip, response.order.tip.percentage]);

  useEffect(() => {
    if (acceptCashAndCheckPayments) setPaymentMethod('offline');
  }, [acceptCashAndCheckPayments, setPaymentMethod]);

  const paymentComponentsOrdered = acceptCashAndCheckPayments ? [...paymentComponents].reverse() : paymentComponents;

  return (
    <div className="payment-method-options">
      {paymentComponentsOrdered.map(paymentItem => {
        if (acceptInPersonPayments && paymentItem.name === 'ach') return;
        if (provider === 'finix' && paymentItem.name === 'ach') return;
        if (isProduction() && provider === 'checkout' && paymentItem.name === 'ach') return;
        if (!allowOfflinePayments && paymentItem.name === 'offline') return;
        if (acceptInPersonPayments && !acceptCashAndCheckPayments && paymentItem.name === 'offline') return;

        return (
          <div key={paymentItem.name} className="block">
            <div className="payment-fields-container">
              <label className="payment-label" htmlFor={`payment-method-${paymentItem.name}`}>
                <img src={paymentItem.icon} alt={paymentItem.name} />
                {paymentItem.label}
              </label>
              <div className="row align-center">
                <span>{paymentItem.secondaryLabel}</span>
                <input
                  id={`payment-method-${paymentItem.name}`}
                  type="radio"
                  name="payment"
                  checked={paymentMethod === paymentItem.name}
                  onChange={() => setPaymentMethod(paymentItem.name)}
                />
              </div>
            </div>
            {paymentMethod === paymentItem.name && (
              <Elements stripe={stripePromise}>
                {paymentItem.component(
                  onSubmit,
                  buttonText,
                  setLoading,
                  loading,
                  response,
                  onResponseChanged,
                  pageId,
                  paymentError,
                  provider,
                  disableInputEmail,
                  providerId,
                )}
              </Elements>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default PaymentMethodOptions;
