import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { generateTrackingParams } from 'utils';
import { APP_CONFIG } from 'api/config';
import LogoLink from 'components/Header/LogoLink';
import './style.scss';

interface HeaderProps {
  className?: string;
  tracking?: string;
  additionalAction?: ReactNode;
}

const Header: FC<HeaderProps> = ({ className, tracking, additionalAction }) => {
  return (
    <header className={clsx('main', className)}>
      <div className="logo">
        <a
          href={`${APP_CONFIG.OMELLA_WEBSITE}?utm_source=${window.location.href}&${generateTrackingParams(tracking)}`}
          target="_blank"
          rel="noreferrer">
          <LogoLink redirect={false} />
        </a>
      </div>
      <div className="menu"></div>
      {additionalAction}
    </header>
  );
};

export default Header;
