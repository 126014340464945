import React, { FC, useState, useRef } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import * as AuthQuery from 'graphql/auth.graphql';
import * as PaymentQuery from 'graphql/payment.graphql';
import useUserSession from 'hooks/useUserSession';
import Alert from 'components/Alert';
import CashierNameModal from './CashierNameModal';
import IconButton from 'components/Button/IconButton';
import ProtectedComponent from '~/components/ProtectedComponent';
import SideModal from 'components/SideModal';
import SideModalHeader from 'components/SideModal/Header';
import ToggleSwitch from 'components/ToggleSwitch';
import { Terminal } from 'api/data/payment/types';
import { SessionPreferences, Session } from 'api/data/user/types';
import './style.scss';

type InPersonPaymentsProps = {
  organizationId: string;
  spaceSlug: string;
};

type InPersonPaymentsModalProps = {
  session: Session;
} & InPersonPaymentsProps;

const InPersonPayments: FC<InPersonPaymentsProps> = ({ organizationId, spaceSlug }) => {
  const { data: userSession } = useUserSession();
  const organization = userSession?.session.managedOrganizations?.find(org => org.id === organizationId);

  if (!organization || !userSession || !organization.hasPaymentTerminals) return null;

  return (
    <ProtectedComponent action="MANAGE_IN_PERSON_PAYMENTS" currentUserOrganizationRole={organization.currentUserRole}>
      <InPersonPaymentsModal organizationId={organizationId} spaceSlug={spaceSlug} session={userSession.session} />
    </ProtectedComponent>
  );
};

const InPersonPaymentsModal: FC<InPersonPaymentsModalProps> = ({ session, organizationId, spaceSlug }) => {
  const cashierModalRef = useRef<HTMLDivElement>(null);
  const [isOpenInPersonModal, setIsOpenInPersonModal] = useState(false);
  const [isOpenCashierModal, setIsOpenCashierModal] = useState(false);
  const [updateSessionPreferences] = useMutation<{
    updateSessionPreferences: {
      sessionPreferences: SessionPreferences;
    };
  }>(AuthQuery.UpdateSessionPreferences);

  const { data: dataTerminals } = useQuery<{ paymentTerminals: Terminal[] }>(PaymentQuery.PaymentTerminals, {
    variables: { organizationId, currentSpace: spaceSlug },
  });

  const { sessionPreferences } = session;

  const handleOnChange = async (field: string, value: boolean) => {
    const input = {
      [field]: value,
    };

    if (field === 'acceptInPersonPayments') {
      input['acceptCashAndCheckPayments'] = value;

      if (value) {
        setIsOpenCashierModal(true);
      }
    }

    await updateSessionPreferences({
      variables: { input },
      update: (cache, { data }) => {
        if (!data?.updateSessionPreferences?.sessionPreferences) return;
        cache.writeQuery({
          query: AuthQuery.Session,
          data: {
            session: {
              ...session,
              sessionPreferences: {
                ...sessionPreferences,
                ...input,
              },
            },
          },
        });
      },
    });
  };

  return (
    <div className="in-person-payments">
      <IconButton icon="credit_card" className="button-size-m " onClick={() => setIsOpenInPersonModal(true)}>
        In Person Payments
      </IconButton>
      <SideModal
        isOpen={isOpenInPersonModal}
        toggle={setIsOpenInPersonModal}
        allowedShards={[cashierModalRef]}
        closeOnOutsideClick={!isOpenCashierModal}
        header={<SideModalHeader title="In Person Payments" onClose={() => setIsOpenInPersonModal(false)} />}>
        <div className="in-person-modal">
          <div className="box accept-in-person-box">
            <ToggleSwitch
              label="Accept in person payments"
              toggleValue={sessionPreferences.acceptInPersonPayments}
              handleOnChange={() =>
                void handleOnChange('acceptInPersonPayments', !sessionPreferences.acceptInPersonPayments)
              }
            />
            {sessionPreferences.acceptInPersonPayments && (
              <div className="in-person-wrapper">
                <div className="horizontal-line"></div>
                <div className="row space-between cashier-wrapper">
                  <span className="size-xxs subtitle-darker label">Cashier</span>
                  <IconButton
                    aria-label="Edit cashier name"
                    icon="edit"
                    className="button-size-m "
                    onClick={() => setIsOpenCashierModal(true)}
                    iconSide="RIGHT">
                    {session.currentUser?.fullName}
                  </IconButton>
                </div>
                <div className="box">
                  <ToggleSwitch
                    label="Cash and check"
                    toggleValue={sessionPreferences.acceptCashAndCheckPayments}
                    handleOnChange={() =>
                      void handleOnChange('acceptCashAndCheckPayments', !sessionPreferences.acceptCashAndCheckPayments)
                    }
                  />
                </div>
                <Alert
                  type="informative"
                  icon="warning_circle_solid"
                  content="Select a reader below to accept card payments"
                />
              </div>
            )}
          </div>
          {!!dataTerminals?.paymentTerminals && dataTerminals?.paymentTerminals.length > 0 && (
            <>
              <p className="subtitle-x-small neutral">Card Readers</p>
              <div className="box card-readers-list">
                {dataTerminals?.paymentTerminals.map(terminal => {
                  return <div key={terminal.id}>{terminal.name}</div>;
                })}
              </div>
            </>
          )}
        </div>
      </SideModal>
      <CashierNameModal modalRef={cashierModalRef} setIsOpen={setIsOpenCashierModal} isOpen={isOpenCashierModal} />
    </div>
  );
};

export default InPersonPayments;
