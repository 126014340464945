import React, { FC, useState } from 'react';
import useUserSession from 'hooks/useUserSession';
import Input from 'components/Input';
import Modal from 'components/Modal';
import './style.scss';

type CashierNameModalProps = {
  setIsOpen: (open: boolean) => void;
  isOpen: boolean;
  modalRef: React.RefObject<HTMLDivElement>;
};

const CashierNameModal: FC<CashierNameModalProps> = ({ setIsOpen, isOpen, modalRef }) => {
  const [cashierName] = useState('');
  const { data: userSession } = useUserSession();

  const handleOnCancel = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      handleOnCancel={handleOnCancel}
      header="Accept in person payments"
      confirmlabel="Save"
      denyLabel="Cancel"
      visible={isOpen}
      className="cashier-name-modal"
      modalRef={modalRef}
      confirmButtonProps={{ disabled: !cashierName }}>
      <Input
        label="Cashier name"
        placeholder="First Last"
        disabled
        value={userSession?.session.currentUser?.fullName || ''}
        name="cashier-name"
        type="text"
        rules={{ required: true }}
      />
    </Modal>
  );
};

export default CashierNameModal;
