import React, { ReactNode } from 'react';
import { OrganizationRoles, SpaceRoles } from '~/api/data/user/types';

export type Action =
  | 'CANCEL_SUBSCRIPTION'
  | 'CREATE_SPACE'
  | 'EDIT_TEMPLATES'
  | 'ORGANIZATION_GOOGLE_SHEET_REPORTS'
  | 'ORGANIZATION_SETTINGS'
  | 'PAGE_SETTINGS'
  | 'PAYER_PROFILE'
  | 'PAYMENT_REFUND'
  | 'SPACE_SETTINGS'
  | 'AUTO_CHECKIN'
  | 'ADD_OR_MODIFY_ORG_ROLE'
  | 'PAYOUTS'
  | 'MANAGE_IN_PERSON_PAYMENTS';

// I created this type because both OrganizationRoles and SpaceRoles can be OWNER. So I need to differentiate them.
type UserRoles = OrganizationRoles | Omit<SpaceRoles, 'OWNER'> | 'SPACE_OWNER' | 'SPACE_STAFF';

interface ProtectedComponentProps {
  children: ReactNode;
  action: Action;
  currentUserOrganizationRole?: OrganizationRoles;
  currentUserSpaceRole?: SpaceRoles;
}

const allowedActionsByRole: Record<Action, UserRoles[]> = {
  CANCEL_SUBSCRIPTION: ['ADMIN', 'OWNER', 'SPACE_OWNER'],
  CREATE_SPACE: ['ADMIN', 'OWNER'],
  EDIT_TEMPLATES: [],
  ORGANIZATION_GOOGLE_SHEET_REPORTS: ['ADMIN', 'OWNER'],
  ORGANIZATION_SETTINGS: ['ADMIN', 'OWNER'],
  PAGE_SETTINGS: ['ADMIN', 'OWNER', 'SPACE_OWNER'],
  PAYER_PROFILE: ['ADMIN', 'OWNER'],
  PAYMENT_REFUND: ['ADMIN', 'OWNER', 'SPACE_OWNER'],
  SPACE_SETTINGS: ['ADMIN', 'OWNER', 'SPACE_OWNER'],
  AUTO_CHECKIN: ['ADMIN', 'OWNER', 'SPACE_OWNER', 'SPACE_STAFF'],
  ADD_OR_MODIFY_ORG_ROLE: ['OWNER'],
  PAYOUTS: ['ADMIN', 'OWNER'],
  MANAGE_IN_PERSON_PAYMENTS: ['ADMIN', 'OWNER'],
};

const interpretRoles = (organizationRole: OrganizationRoles, spaceRole?: SpaceRoles): UserRoles => {
  if (organizationRole === 'SPACE' && spaceRole === 'OWNER') return 'SPACE_OWNER';
  if (organizationRole === 'SPACE' && spaceRole === 'STAFF') return 'SPACE_STAFF';
  return organizationRole;
};

export function checkUserRole(
  action: Action,
  currentUserOrganizationRole?: OrganizationRoles,
  currentUserSpaceRole?: SpaceRoles,
) {
  if (currentUserOrganizationRole === 'OMELLA_ADMIN') return true;
  if (!currentUserOrganizationRole) return false;
  if (currentUserOrganizationRole === 'SPACE' && !currentUserSpaceRole) return false;

  const allowedRolesByAction = allowedActionsByRole[action];
  return allowedRolesByAction.includes(interpretRoles(currentUserOrganizationRole, currentUserSpaceRole));
}

export default function ProtectedComponent({
  children,
  action,
  currentUserOrganizationRole,
  currentUserSpaceRole,
}: ProtectedComponentProps) {
  return checkUserRole(action, currentUserOrganizationRole, currentUserSpaceRole) ? <>{children}</> : null;
}
